import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { StateService } from 'src/app/state.service';
import { CheckInService } from 'src/app/check-in.service';
import { Question } from 'src/app/models/question';
import { CheckIn } from 'src/app/models/checkin';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {
  questionIndex: number;
  numOfQuestions: number;
  question: Question;

  constructor(private stateService: StateService, private checkInService: CheckInService, private router: Router, private route: ActivatedRoute) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit(): void {
    this.questionIndex = parseInt(this.route.snapshot.paramMap.get("questionIndex"));
    this.numOfQuestions = this.stateService.getNumOfQuestions();
    this.question = this.stateService.getQuestion(this.questionIndex);
  }

  onNo() {
    this.checkInService.answerQuestion(this.question.id, false);
    this.goToNextPage();
  }

  onYes() {
    this.checkInService.answerQuestion(this.question.id, true);
    this.goToNextPage();
  }

  private goToNextPage() {
    if(this.questionIndex + 1 < this.numOfQuestions) {
      this.router.navigate(["../../question", this.questionIndex + 1], { relativeTo: this.route });
    } else {
      this.router.navigate(["../../checkInConfirm"], { relativeTo: this.route });
    }
  }

  onBack() {
    if(this.questionIndex - 1 < 0) {
      this.router.navigate(["../../contactInfoTwo"], { relativeTo: this.route });
    } else {
      this.router.navigate(["../../question", this.questionIndex - 1], { relativeTo: this.route });
    }
  }
}
