import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CheckInResponse, CheckInStatus } from './check-in';
import { Observable, timer, empty } from 'rxjs';
import { switchMap, shareReplay } from 'rxjs/operators';
import { AuthService } from 'src/shared/auth.service';

@Injectable()
export class CheckInService {
  private checkIns$ : { [key: string]: Observable<CheckInResponse[]> } = {};

  constructor(private http: HttpClient, authService: AuthService) {
    authService.onLogout.subscribe(() => this.checkIns$ = {});
  }

  getCheckIns(businessId: string, duration: number, statusFilters: CheckInStatus[]) {
    if (!businessId) {
      return empty();
    }

    let url = `${environment.apiBaseUrl}/business/${businessId}/checkIn?durationFilter=${duration}&statusFilter=${statusFilters.sort().join(',')}`;
    if (!this.checkIns$[url]) {
      this.checkIns$[url] = timer(0, 30000).pipe(
        switchMap(() => this.http.get<CheckInResponse[]>(url)),
        shareReplay({ bufferSize: 1, refCount: true })
      );
    }

    return this.checkIns$[url];
  }
}