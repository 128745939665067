import { Component, OnInit } from "@angular/core";

@Component({
  selector: 'a[wq-link]',
  templateUrl: 'link.component.html',
  styleUrls: [
    'link.component.scss'
  ]
})
export class LinkComponent implements OnInit {
  ngOnInit() {
    console.log('Linking here');
  }
}