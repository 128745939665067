import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { switchMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.authService.isLoggedIn()).pipe(
      switchMap(loggedIn => 
        {
          if (!loggedIn) {
            return throwError('Not logged in!');
          }
          
          return from(this.authService.getToken())
        }),
      switchMap(token => {
        const authReq = req.clone({
          headers: req.headers.append('Authorization', token)
        });

        return next.handle(authReq);
      }),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          return throwError(err);
        }
        
        return next.handle(req);
      })
    );
  }

}
