import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule }    from '@angular/common/http';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { HammerModule } from '@angular/platform-browser';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import { ContactInfoOneComponent } from './contact-info-one/contact-info-one.component';
import { ContactInfoTwoComponent } from './contact-info-two/contact-info-two.component';
import { QuestionComponent } from './question/question.component';
import { CheckInConfirmComponent } from './check-in-confirm/check-in-confirm.component';
import { CheckInCompleteComponent } from './check-in-complete/check-in-complete.component';
import { StepDotsComponent } from './step-dots/step-dots.component';
import { ModalComponent } from './modal/modal.component';
import { HomeComponent } from './home/home.component';
import { WebComponent } from './web/web.component';
import { SliderComponent } from './slider/slider.component';

import * as hammer from 'hammerjs';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { SupportComponent } from './support/support.component';
import { PrivacyWrapperComponent } from './privacy-wrapper/privacy-wrapper.component';
import { TermsWrapperComponent } from './terms-wrapper/terms-wrapper.component';
import { SupportWrapperComponent } from './support-wrapper/support-wrapper.component';
import { SharedModule } from 'src/shared/shared.module';
import { AuthModule } from 'src/auth/auth.module';
import { PortalModule } from 'src/portal/portal.module';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    swipe: { direction: hammer.DIRECTION_HORIZONTAL },
    pinch: { enable: false },
    rotate: { enable: false }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    ContactInfoOneComponent,
    ContactInfoTwoComponent,
    QuestionComponent,
    CheckInConfirmComponent,
    CheckInCompleteComponent,
    StepDotsComponent,
    ModalComponent,
    HomeComponent,
    WebComponent,
    SliderComponent,
    SupportComponent,
    SupportWrapperComponent,
    PrivacyComponent,
    PrivacyWrapperComponent,
    TermsComponent,
    TermsWrapperComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    HammerModule,
    FontAwesomeModule,
    AuthModule,
    PortalModule,
    SharedModule
  ],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
