import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'support-wrapper',
  template: '<support></support>'
})
export class SupportWrapperComponent {
  constructor(title: Title) {
    title.setTitle('WaitQue - Support');
  }
}