<div *ngIf="loading">
  <app-loader></app-loader>
</div>
<form #forgotForm="ngForm" class="form" *ngIf="!loading">
  <wq-textbox title="Email Address" name="email" [(ngModel)]="email" [required]="true"></wq-textbox>

  <div class="button-container">
    <button primary (click)="resetPassword()" [disabled]="forgotForm.form.invalid">Reset Password</button>
  </div>
</form>
