import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { timer } from 'rxjs';

import { StateService } from 'src/app/state.service';
import { CheckInService } from 'src/app/check-in.service';
import { isString } from 'lodash';

@Component({
  selector: 'app-check-in-confirm',
  templateUrl: './check-in-confirm.component.html',
  styleUrls: ['./check-in-confirm.component.scss']
})
export class CheckInConfirmComponent implements OnInit {
  error: string;
  checkInSubmitted: boolean = false;
  showPrivacyModal: boolean = false;
  showTosModal: boolean = false;

  constructor(private stateService: StateService, private checkInService: CheckInService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.checkInService.checkInStatus.subscribe(() => {
      timer(10000).subscribe(val => {
        if(this.hasUnprocessedAnswers()) {
          this.error = 'Oops something went wrong. Please try checking in again.';
        }
      });
    }, err => {
      this.handleError(err);
    })
  }

  hasUnprocessedAnswers(): boolean {
    return this.checkInService.hasUnprocessedAnswers();
  }

  onCompleteCheckin() {
    this.checkInSubmitted = true;
    this.checkInService.completeCheckIn().subscribe(() => {
      this.router.navigate(["../checkInComplete"], { relativeTo: this.route });
    },
    error => {
      this.handleError(error);
    });
  }

  onBack() {
    let numOfQuestions = this.stateService.getNumOfQuestions();
    this.router.navigate(["../question", numOfQuestions - 1], { relativeTo: this.route });
  }

  openPrivacyModal() {
    this.showPrivacyModal = true;
  }

  privacyCloseClicked(close: boolean) {
    this.showPrivacyModal = false;
  }

  openTosModal() {
    this.showTosModal = true;
  }

  tosCloseClicked(close: boolean) {
    this.showTosModal = false;
  }

  private handleError(error: any) {
    if (isString(error)) {
      this.error = error;
    } else {   
      this.error = 'Oops something went wrong. Please try checking in again.';
    }
  }
}
