<div class="main">
  <div class="heading">
      <div class="logo">
          <img src="assets/logo_140.png" height="70px" width="234px" />
      </div>
      <div class="title">
        Support & FAQs
      </div>
  </div>
  <div class="body">
    <div class="support-item" *ngFor="let faq of faqs">
      <div class="support-heading" (click)="faq.open = !faq.open">
        <div class="name">
          {{ faq.title }}
        </div>
        <fa-icon [icon]="faChevronRight" *ngIf="!faq.open"></fa-icon>
        <fa-icon [icon]="faChevronDown" *ngIf="faq.open"></fa-icon>
      </div>
      <div class="content" *ngIf="faq.open">
        {{ faq.content }}
      </div>
    </div>
  </div>
</div>