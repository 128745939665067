import { Component } from '@angular/core';
import { AuthService } from 'src/shared/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  styleUrls: [
    'login.component.scss'
  ]
})
export class LoginComponent {
  email: string;
  password: string;
  loading = false;
  error: string;

  constructor(private authService: AuthService, private router: Router) {}

  login() {
    this.loading = true;
    this.authService.login(this.email, this.password).then(() => {
      this.router.navigate(['portal']);
    }).catch(err => {
      this.loading = false;
      if (err.code === 'NotAuthorizedException') {
        this.error = err.message;
      } else if (err.code === 'UserNotConfirmedException') {
        this.authService.sendCode(this.email);
        this.router.navigate(['/auth/sign-up-confirmation'], {
          queryParams: {
              email: this.email
          }
        });
      } else {
        this.error = 'Oops, an unexpected error occurred logging in, please try again later.'
      }
    });
  }
}
