<div class="message" [class]="status">
  <div class="icon" *ngIf="status">
    <fa-icon [icon]="icons[status]"></fa-icon>
  </div>
  <div class="content">
    <div class="title" *ngIf="title">{{ title }}</div>
    <div class="body">
      <ng-content></ng-content>
    </div>
  </div>
</div>