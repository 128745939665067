<wq-card title="Update Business Info">
  <wq-message title="Business Update Failed" *ngIf="error" status="error">
    {{ error }}
  </wq-message>
  <form #businessForm="ngForm" class="form" *ngIf="business && !loading">
    <business-setup [business]="business"></business-setup>
    <div class="button-container">
      <button primary (click)="updateBusiness()" [disabled]="businessForm.form.invalid">Update Business</button>
    </div>
  </form>
  <app-loader *ngIf="!business || loading"></app-loader>
</wq-card>