import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ContactUsRequest } from './contact-us.request';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {
  constructor(private httpClient: HttpClient) {}

  contactUs(request: ContactUsRequest) {
    return this.httpClient.post(`${environment.apiBaseUrl}/contact-us-public`, request);
  }
}