import { Component, OnInit, Input } from "@angular/core";
import Highcharts from 'highcharts';
import moment from 'moment';
import { CheckInService } from '../check-in.service';
import { MeService } from '../me.service';
import { switchMap } from 'rxjs/operators';
import { isEmpty, chain, values, map, filter } from 'lodash';
import { CheckInStatus, CheckInResponse } from '../check-in';
const noData = require('highcharts/modules/no-data-to-display')
noData(Highcharts)

@Component({
  selector: 'check-ins-chart',
  templateUrl: 'check-ins-chart.component.html',
  styleUrls: [
    'check-ins-chart.component.scss'
  ]
})
export class CheckInsChartComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options;
  private static legendMap = {
    'checked-in': 'Checked In',
    'inside': 'Inside',
    'rejected': 'Rejected',
    'left': 'Left',
    'no-show': 'No Show',
    'incomplete': 'Incomplete',
  }

  @Input()
  duration: number;

  update = false;

  constructor(private checkInService: CheckInService, private meService: MeService) {}

  ngOnInit() {
    this.meService.getMe().pipe(switchMap(me => {
      return this.checkInService.getCheckIns(me.businessIds[0], this.duration, [ CheckInStatus.CheckedIn, CheckInStatus.Inside, CheckInStatus.Rejected, CheckInStatus.NoShow, CheckInStatus.Left ])
    })).subscribe(checkIns => {
      let dates = this.getDates(this.duration);
      let series = {};

      if (!isEmpty(checkIns)) {
        series[CheckInStatus.CheckedIn] = {};
        series[CheckInStatus.Left] = {};
        series[CheckInStatus.NoShow] = {};
        series[CheckInStatus.Rejected] = {};

        chain(dates).forEach(date => {
          series[CheckInStatus.CheckedIn][date] = this.getCount(checkIns, null, date);
          series[CheckInStatus.Left][date] = this.getCount(checkIns, CheckInStatus.Left, date);
          series[CheckInStatus.NoShow][date] = this.getCount(checkIns, CheckInStatus.NoShow, date);
          series[CheckInStatus.Rejected][date] = this.getCount(checkIns, CheckInStatus.Rejected, date);
        }).value();
      }

      this.chartOptions = {
        chart: {
          type: 'column'
        },
        title: null,
        xAxis: {
          categories: dates.map(this.formatDate)
        },
        yAxis: {
          title: {
            text: 'Count'
          }
        },
        series: map(series, (value, key) => {
          return {
            name: CheckInsChartComponent.legendMap[key],
            data: values(value),
            type: 'column'
          };
        }),
        credits: {
          enabled: false
        }
      };

      this.update = true;
    });
  }

  private getCount(checkIns: CheckInResponse[], status: CheckInStatus, date: moment.Moment) {
    return filter(checkIns, checkIn => {
      return (status == null || checkIn.status === status) && date.isSame(moment(checkIn.checkinDateTime).startOf('day'))
    }).length;
  }

  private getDates(duration: number) {
    let date = moment().startOf('day').add(-1 * duration, 'days');
    let dates = [
      date.clone()
    ];

    for (var i = 1; i <= duration; i++) {
      dates.push(date.add(1, 'days').clone());
    }

    return dates;
  }
  
  private formatDate(date: moment.Moment) {
    return date.format('MM/DD/YYYY');
  }
}