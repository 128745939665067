import Amplify, { Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedOut$: ReplaySubject<void>;

  constructor() {
    this.loggedOut$ = new ReplaySubject<void>(1);

    Amplify.configure({
      Auth: {
        userPoolId: environment.auth.userPoolId,
        userPoolWebClientId: environment.auth.clientId
      }
    })
  }

  get onLogout(): Observable<void> {
    return this.loggedOut$.asObservable();
  }

  confirm(email: string, code: string) {
    return Auth.confirmSignUp(email, code);
  }

  login(email: string, password: string) {
    return Auth.signIn(email, password);
  }

  logout() {
    this.loggedOut$.next(null);
    return Auth.signOut();
  }

  isLoggedIn() {
    return Auth.currentAuthenticatedUser().then(user => {
      return !!user;
    }).catch(err => {
      return false;
    });
  }

  getToken(): Promise<string> {
    return this.isLoggedIn().then((loggedIn) => {
      if (!loggedIn) {
        return Promise.reject('Not logged in!');
      }

      return Auth.currentSession().then(session => {
        return session.getAccessToken().getJwtToken();
      });
    });
  }

  resetPassword(email: string) {
    return Auth.forgotPassword(email);
  }

  resetPasswordConfirm(email: string, code: string, password: string) {
    return Auth.forgotPasswordSubmit(email, code, password);
  }

  sendCode(email: string) {
    return Auth.resendSignUp(email);
  }

  signUp(email: string, firstName: string, lastName: string, password: string) {
    return Auth.signUp({
      username: email,
      password,
      attributes: {
        email: email,
        given_name: firstName,
        family_name: lastName,
        name: `${firstName} ${lastName}`,
        zoneinfo: 'TZ_NewYork'
      }
    });
  }
}
