<div class="loading" *ngIf="!question">
    <app-loader></app-loader>
</div>
<div class="main" *ngIf="question">
    <app-step-dots currentStep="{{questionIndex + 2}}"></app-step-dots>
    <div class="title">
        Question {{questionIndex + 1}} of {{numOfQuestions}}:
    </div>
    <div class="question">
        {{question.question}}
    </div>
    <div class="spacer">
            
    </div>
    <div class="button-container">
        <button class="next-button" (click)="onNo()">No</button>
    </div>
    <div class="button-container">
        <button class="next-button" (click)="onYes()">Yes</button>
    </div>
    <div class="button-container">
        <button class="back-button" (click)="onBack()">Back</button>
    </div>
</div>