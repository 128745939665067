<div class="main">
    <div class="top-container">
        <div class="left-container">
            <div class="logo">
                <img src="assets/logo_140.png" height="70px" width="234px">
            </div>
            <div class="spacer"></div>
            <div class="banner-text-container">
                <div class="banner-text">The simple, flexible & free check-in & waitlist app for businesses</div>
            </div>
            <div class="spacer"></div>
            <div class="app-store-links">
                <div class="apple-store-link"><a href='https://apps.apple.com/us/app/id1516960090'><img src="assets/apple-app-store-badge.png"></a></div>
                <div class="google-store-link"><a href='https://play.google.com/store/apps/details?id=com.waitque.mobile&utm_source=website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img src="assets/google-play-badge.png"></a></div>
            </div>
        </div>
        <div class="right-container">
          <div class="top-buttons">
            <button class="button" [routerLink]="['/auth/sign-up']">Sign Up</button>
            <button class="button" [routerLink]="['/portal']">Login</button>
          </div>
          <img class="big-logo-icon" src="assets/logo_icon_673.gif">
        </div>
    </div>
    <div class="content-container">
        <div class="title">Features & Cost</div>
        <div class="features-container">
            <div class="feature">
                <div class="feature-title">Improve Guest Experience</div>
                <ul class="detail">
                    <li>Replace your paper check-in process with WaitQue's digitial solution</li>
                    <li>Easily allow your customer to check-in & manage your waitlist</li>
                    <li>Easily track the number of customers inside of your business</li>
                </ul>
            </div>
            <div class="feature">
                <div class="feature-title">Simple & Configurable</div>
                <ul class="detail">
                    <li>Web interface for your customers; they don’t have to install anything</li>
                    <li>Mark your business open / closed in the app to enable / disable check-ins</li>
                    <li>Simple dashboard within the app to manage check-ins</li>
                </ul>
            </div>
            <div class="feature">
                <div class="feature-title">Flexible</div>
                <ul class="detail">
                    <li>Supports email, phone calls or SMS to notify customers when you are ready for them</li>
                    <li>Designed for iOS (iPhone & iPad) and Android devices</li>
                </ul>
            </div>
            <div class="feature">
                <div class="feature-title">Cost</div>
                <ul class="detail">
                    <li>It’s free and powered by The RRS Group</li>
                    <li>There are no limits to the number of check-ins</li>
                    <li>No credit card or payment information is collected or required</li>
                </ul>
            </div>
        </div>
        <div class="button-container">
          <button class="button" [routerLink]="['/auth/sign-up']">Sign Up For Your Free Account</button>
        </div>
    </div>
    <app-slider></app-slider>
    <div class="content-container">
        <div class="title">How WaitQue Works</div>
        <div class="step-container">
            <div class="step">Signup your business through the app</div>
            <div class="step">Post the check-in link and QR code</div>
            <div class="step">Your customers open the link or scan the QR code</div>
            <div class="step">Your customers check-in and wait</div>
            <div class="step">Get notified when customers check-in via the app</div>
            <div class="step">Notify your customers via the app; the app will email / call / text the customer to let them know you are ready for them</div>
        </div>
    </div>
    <div class="contact-us-container">
        <div class="title">Contact Us</div>
        <div class="form-container" *ngIf="!messageSent">
            <div class="form-input">
                <input class="contact-us-input" type="email" placeholder="Email" [(ngModel)]="contactUsRequest.email" (keyup)="checkEmailError()" name="email">
                <div class="input-error" *ngIf="emailError">
                    {{emailError}}
                </div>
            </div>
            <div class="form-input">
                <input class="contact-us-input" type="text" placeholder="Name" [(ngModel)]="contactUsRequest.name" (keyup)="checkNameError()" name="name">
                <div class="input-error" *ngIf="nameError">
                    {{nameError}}
                </div>
            </div>
            <div class="form-input">
                <input class="contact-us-input" type="text" placeholder="Subject" [(ngModel)]="contactUsRequest.subject" (keyup)="checkSubjectError()" name="subject">
                <div class="input-error" *ngIf="subjectError">
                    {{subjectError}}
                </div>
            </div>
        </div>
        <div class="form-container" *ngIf="!messageSent">
            <div class="form-input">
                <textarea class="message-input" placeholder="Message" rows="8" [(ngModel)]="contactUsRequest.message" (keyup)="checkMessageError()" name="message"></textarea>
                <div class="input-error" *ngIf="messageError">
                    {{messageError}}
                </div>
            </div>
        </div>
        <div class="recaptcha-container" *ngIf="!messageSent">
            <re-captcha (resolved)="resolved($event)"
                siteKey="6LfVRgEVAAAAAAn13ba1oZs-Ghq0uwDWu4LD9ZWy">
            </re-captcha>
            <div class="input-error" *ngIf="recaptchaError">
                {{recaptchaError}}
            </div>
        </div>
        <div class="button-container" *ngIf="!messageSent">
            <button class="button" (click)="onSend()" >Send Message</button>
        </div>
        <div class="check-icon-container" *ngIf="messageSent">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ffffff" width="100px" height="100px"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>
        </div>
        <div class="message-sent-container" *ngIf="messageSent">
            Message Sent! Someone will be in contact with your shortly.
        </div>
    </div>
    <div class="footer-container">
        <div class="left-footer">
            WaitQue LLC © 2020 | <span class="link" (click)="openPrivacyModal()">Privacy Policy</span> | <span class="link" (click)="openTosModal()">Terms of Service</span> | <a href="support" class="link">Support & FAQs</a>
        </div>
        <div class="spacer-footer">

        </div>
        <div class="right-footer">
            Follow us on:
        </div>
        <div class="social-container">
            <div class="twitter-footer">
                <a href="https://twitter.com/wait_que">
                    <svg id="Logo_FIXED" data-name="Logo — FIXED" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" height="36px"><defs><style>.cls-1{fill:none;}</style></defs><title>Twitter_Logo_Blue</title><rect class="cls-1" width="400" height="400"/><path class="cls-2" d="M153.62,301.59c94.34,0,145.94-78.16,145.94-145.94,0-2.22,0-4.43-.15-6.63A104.36,104.36,0,0,0,325,122.47a102.38,102.38,0,0,1-29.46,8.07,51.47,51.47,0,0,0,22.55-28.37,102.79,102.79,0,0,1-32.57,12.45,51.34,51.34,0,0,0-87.41,46.78A145.62,145.62,0,0,1,92.4,107.81a51.33,51.33,0,0,0,15.88,68.47A50.91,50.91,0,0,1,85,169.86c0,.21,0,.43,0,.65a51.31,51.31,0,0,0,41.15,50.28,51.21,51.21,0,0,1-23.16.88,51.35,51.35,0,0,0,47.92,35.62,102.92,102.92,0,0,1-63.7,22A104.41,104.41,0,0,1,75,278.55a145.21,145.21,0,0,0,78.62,23"/></svg>
                </a>
            </div>
            <div class="fb-footer">
                <a href="https://www.facebook.com/WaitQue-103720108057748">
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        width="24px" height="24px" viewBox="0 0 266.893 266.895" enable-background="new 0 0 266.893 266.895"
                        xml:space="preserve">
                    <path id="Blue_1_" class="fb-logo-bg" d="M248.082,262.307c7.854,0,14.223-6.369,14.223-14.225V18.812
                        c0-7.857-6.368-14.224-14.223-14.224H18.812c-7.857,0-14.224,6.367-14.224,14.224v229.27c0,7.855,6.366,14.225,14.224,14.225
                        H248.082z"/>
                    <path id="f" class="fb-logo" d="M182.409,262.307v-99.803h33.499l5.016-38.895h-38.515V98.777c0-11.261,3.127-18.935,19.275-18.935
                        l20.596-0.009V45.045c-3.562-0.474-15.788-1.533-30.012-1.533c-29.695,0-50.025,18.126-50.025,51.413v28.684h-33.585v38.895h33.585
                        v99.803H182.409z"/>
                    </svg>
                </a>
            </div>
            <div class="linkedin-footer">
                <a href="https://www.linkedin.com/organization-guest/company/waitque">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="linkedin-logo"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg>
                </a>
            </div>
        </div>
    </div>
</div>
<app-modal contentType="privacy" (closeEvent)="privacyCloseClicked($event)" *ngIf="showPrivacyModal"></app-modal>
<app-modal contentType="tos" (closeEvent)="tosCloseClicked($event)" *ngIf="showTosModal"></app-modal>
