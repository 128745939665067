import { NgModule } from "@angular/core";
import { HighchartsChartModule } from 'highcharts-angular';
import { PortalRoutingModule } from './portal-routing.module';
import { SharedModule } from 'src/shared/shared.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PortalComponent } from './portal/portal.component';
import { MeService } from './me.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CheckInsChartComponent } from './check-ins-chart/check-ins-chart.component';
import { CheckInService } from './check-in.service';
import { TodayStatusComponent } from './today-status/today-status.component';
import { BusinessSetupComponent } from './business-setup/business-setup.component';
import { BusinessService } from './business.service';
import { BusinessCreateComponent } from './business-create/business-create.component';
import { BusinessUpdateComponent } from './business-update/business-update.component';
import { CheckInsComponent } from './check-ins/check-ins.component';

@NgModule({
  declarations: [
    BusinessCreateComponent,
    BusinessSetupComponent,
    BusinessUpdateComponent,
    CheckInsChartComponent,
    CheckInsComponent,
    DashboardComponent,
    PortalComponent,
    TodayStatusComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    HighchartsChartModule,
    PortalRoutingModule,
    SharedModule
  ],
  providers: [
    BusinessService,
    CheckInService,
    MeService
  ]
})
export class PortalModule {}
