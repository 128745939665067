<form #resetPasswordForm="ngForm" class="form" *ngIf="!loading && !reset">
  <wq-message title="Reset Password" status="info" *ngIf="!error">
    Please enter the verification code sent to you and your new password!
  </wq-message>
  <wq-message title="Reset Password Failed" status="error" *ngIf="error">
    {{ error }}
  </wq-message>
  <wq-textbox title="Code" [(ngModel)]="code" name="code" [required]="true"></wq-textbox>
  <wq-textbox title="Password" type="password" [(ngModel)]="password" name="password" [required]="true"></wq-textbox>

  <div class="button-container">
    <button primary (click)="resetPassword()" [disabled]="resetPasswordForm.form.invalid">Reset Password</button>
  </div>
</form>
<wq-message status="success" title="Password Reset" *ngIf="reset">
  Your password has been successfully reset, you will be redirected to login to WaitQue!
</wq-message>
<app-loader *ngIf="loading"></app-loader>
