<div class="main">
    <div class="spacer">
        
    </div>
    <div class="check-icon-container">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#2ecb72" width="100px" height="100px"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>
    </div>
    <div class="text">
        You are checked in!        
    </div>
    <div class="text">
        You will receive {{contactPreference}} when it’s your turn to come in.            
    </div>
    <div class="spacer">
            
    </div>
</div>