import { Component, Input } from "@angular/core";
import { faInfo, faInfoCircle, faCheckCircle, faTimesCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'wq-message',
  templateUrl: 'message.component.html',
  styleUrls: [
    'message.component.scss'
  ]
})
export class MessageComponent {
  @Input()
  title: string;

  @Input()
  status: 'info' | 'error' | 'warn' | 'success';

  icons = {
    info: faInfoCircle,
    error: faTimesCircle,
    warn: faExclamationCircle,
    success: faCheckCircle
  }
}