<div class="grid" *ngIf="!loading" ngModelGroup="business">
  <wq-textbox title="Business Name"
              [(ngModel)]="business.name"
              name="name"
              [required]="true">
  </wq-textbox>
  <wq-textbox title="Phone Number"
              [(ngModel)]="business.phoneNumber"
              name="phoneNumber"
              [required]="true">
  </wq-textbox>
  <wq-textbox title="Address Line 1"
              [(ngModel)]="business.addressLine1"
              name="addressLine1"
              [required]="true">
  </wq-textbox>
  <wq-textbox title="Address Line 2"
              [(ngModel)]="business.addressLine2"
              name="addressLine2"
              [required]="false">
  </wq-textbox>
  <wq-textbox title="City"
              [(ngModel)]="business.city"
              name="city"
              [required]="true">
  </wq-textbox>
  <wq-dropdown title="State"
               [(ngModel)]="business.state"
               name="state"
               [required]="true">
    <option wq-dropdown-item *ngFor="let state of stateList" [name]="state" [value]="state"></option>
  </wq-dropdown>
  <wq-textbox title="Zip"
              [(ngModel)]="business.zip"
              name="zip"
              [required]="true">
  </wq-textbox>
  <wq-textbox title="Website URL"
              [(ngModel)]="business.websiteUrl"
              name="websiteUrl"
              [required]="false">
  </wq-textbox>
  <wq-dropdown title="Type Of Business"
                [(ngModel)]="business.typeOfBusiness"
                name="typeOfBusinesss"
                [required]="true">
    <option wq-dropdown-item *ngFor="let typeOfBusiness of typesOfBusiness" [name]="typeOfBusiness" [value]="typeOfBusiness"></option>
  </wq-dropdown>
  <wq-textbox title="Capacity"
              type="number"
              [(ngModel)]="business.capacity"
              name="capacity"
              [required]="true">
  </wq-textbox>
</div>
<app-loader *ngIf="loading"></app-loader>