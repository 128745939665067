<div class="loading" *ngIf="(hasUnprocessedAnswers() || checkInSubmitted) && !error">
    <app-loader></app-loader>
</div>
<div class="main" *ngIf="!hasUnprocessedAnswers() && !checkInSubmitted && !error">
    <div class="spacer">
        
    </div>
    <div class="question">
        By completing check-in, you confirm that all of the information entered is correct and that you agree to our <span class="link" (click)="openTosModal()">Terms of Service</span> & <span class="link" (click)="openPrivacyModal()">Privacy Policy</span>.
    </div>
    <div class="spacer">
            
    </div>
    <div class="button-container">
        <button class="next-button" (click)="onCompleteCheckin()">Complete Check In</button>
    </div>
    <div class="button-container">
        <button class="back-button" (click)="onBack()">Back</button>
    </div>
    <app-modal contentType="privacy" (closeEvent)="privacyCloseClicked($event)" *ngIf="showPrivacyModal"></app-modal>
    <app-modal contentType="tos" (closeEvent)="tosCloseClicked($event)" *ngIf="showTosModal"></app-modal>
</div>
<div class="error-container" *ngIf="error">
    <div class="spacer">
            
    </div>
    <div class="error-icon-container">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red" width="100px" height="100px"><path d="M0 0h24v24H0z" fill="none"/><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"/></svg>
    </div>
    <div class="error">
        {{ error || 'Oops something went wrong. Please try checking in again.' }}
    </div>
    <div class="spacer">
            
    </div>
</div>