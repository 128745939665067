import { Component, OnInit, Input } from '@angular/core';

import { StateService } from 'src/app/state.service';

@Component({
  selector: 'app-step-dots',
  templateUrl: './step-dots.component.html',
  styleUrls: ['./step-dots.component.scss']
})
export class StepDotsComponent implements OnInit {
  @Input()
  currentStep: number;
  cssClassArray: string[] = [];

  constructor(private stateService: StateService) { }

  ngOnInit(): void {
    let totalNumOfSteps = this.stateService.getNumOfQuestions() + 2;
    
    for(let i = 0; i < totalNumOfSteps; i++) {
      if(i == this.currentStep) {
        this.cssClassArray.push('selected-dot');
      } else {
        this.cssClassArray.push('dot');
      }
    }
  }

}
