import { Injectable } from '@angular/core';
import { Business } from 'src/app/models/business';
import { Customer } from 'src/app/models/customer';
import { Question } from 'src/app/models/question';
import { CheckIn } from 'src/app/models/checkin';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private _business: Business;
  private _customer: Customer;
  private _questions: Question[];
  private _checkin: CheckIn;

  constructor() { 
    this._customer = {
      id: null,
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      contactPreference: 'SMS',
      acceptPromotionalOffers: true
    };

    this._checkin = {
      id: null,
      customerId: null,
      businessId: null,
      partySize: 1
    }
  }

  setBusiness(business: Business) {
    this._business = business;
  }

  getBusiness(): Business {
    return this._business;
  }

  getCustomer(): Customer {
    return this._customer;
  }

  setQuestions(questions: Question[]) {
    this._questions = questions;
  }

  getQuestion(index: number): Question {
    if(this._questions && index < this._questions.length) {
      return this._questions.find(question => question.order == index);
    } else {
      return null;
    }
  }

  getNumOfQuestions(): number {
    return this._questions ? this._questions.length : 0;
  }

  getCheckIn(): CheckIn {
    return this._checkin;
  }
}
