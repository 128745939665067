<div class="main">
  <div class="heading">
      <div class="logo">
          <img src="assets/logo_140.png" height="70px" width="234px" />
      </div>
  </div>
  <div class="body">
    <p>Scroll to see the entire document</p>
    <p>Effective Date: May 23, 2020</p>


    <p>PLEASE READ THESE TERMS OF SERVICE CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS. THESE INCLUDE VARIOUS LIMITATIONS AND EXCLUSIONS, AND A CLAUSE THAT SETS FORTH GOVERNING LAW AND JURISDICTION AND VENUE FOR DISPUTES.</p>


    <p>WaitQue LLC (referred to as “WaitQue,” “us,” “our,” or “we”) provides the https://www.WaitQue.com site (“Site”), related mobile applications (“Mobile Applications” or “Mobile Apps”), if any, and various related services (the Mobile Applications, Site, and various related services shall collectively be known as “Services”) subject to your compliance with these terms, conditions, and notices contained or referenced herein (the “Terms of Service”), as well as any other written agreement between us and you or your company. In addition, when using particular Services, users shall be subject to any posted guidelines or rules applicable to such Services that may contain terms and conditions in addition to those in these Terms of Service. All such guidelines or rules are hereby incorporated by reference into these Terms of Service. Each of you or us may also be referred to individually as a “party” and together as the “parties”.</p>


    <p>BY DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING OR USING THE SERVICES, YOU AGREE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS OF SERVICE. IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS OF SERVICE, PLEASE DISCONTINUE USE OF THE SITE OR MOBILE APPLICATIONS IMMEDIATELY. Your remedy for dissatisfaction with our Services, content, or other information available on or through our Site or Mobile Apps, is to stop using our Services. If you accept or agree to these Terms of Service on behalf of a company or other legal entity, you represent and warrant that you have the authority to bind that company or other legal entity to these Terms of Service and, in such event, “you” and “your” will refer to and apply to that company or other legal entity.</p>


    <h4>Your access to our Services</h4>


    <p>To access our Services or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of our Services that all the information you provide will be correct, current, and complete and that you will abide by these Terms of Service. If we believe that the information you provide is not correct, current, or complete or that you have violated any provision in these Terms of Service, we have the right to suspend your access to any of our Services or the services of our third-party service partners, if any, or to temporarily suspend or permanently terminate your user account, with or without a prorated refund of any prepaid amounts, if any, in our sole discretion. You agree that all information you provide to register on our Site or Mobile Apps, is governed by our Privacy Policy, located at https://www.WaitQue.com/#privacy and hereby incorporated by reference and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</p>


    <h4>Passwords</h4>


    <p>If you choose, or are provided with, a user name, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this Site or portions of it using your user name, password or other security information. You agree to notify us immediately of any unauthorized access to, or use of, your user name or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.</p>


    <h4>Restrictions on your use</h4>


    <p>You are granted a nonexclusive, nontransferable, revocable, limited license to access and use our Services in accordance with these Terms of Service. You may use our Site and Mobile Apps for purposes expressly permitted by us. Only the authorized account holder may participate as a licensee and user of our Services. The authorized account holder is deemed to be the natural person who is assigned an email address by an Internet access provider, online service provider, or other organization, which is responsible for assigning email addresses or the domain associated with the submitted email address. As a condition of your use of our Services, you warrant to us that you will not use our Services for any purpose that is prohibited by these terms, conditions, and notices and will do none of the following:</p>

    <ul>
    <li>Conduct or promote any illegal activities, including, but not limited to, intellectual property infringement or engage in conduct which we, in our sole discretion, find abusive or objectionable while using our Services;</li>
    <li>Transmit any information that we believe to be, in our sole discretion, abusive, excessively violent, harmful to minors, harassing, defamatory, vulgar, obscene, lewd, lascivious, or otherwise objectionable; </li>
    <li>Attempt to reverse engineer or jeopardize the correct functioning of the Services, or otherwise attempt to derive the source code of the software (including the tools, methods, processes, and infrastructure) that enables or underlies the Services;</li>
    <li>Attempt to gain access to secured portions of the Services to which you do not possess access rights or obtain or attempt to obtain any materials, content, or information through any means not intentionally made available or provided for through the Site or Mobile Apps;</li>
    <li>Upload or transmit any form of virus, worm, Trojan horse, or other malicious code;</li>
    <li>Use the Site or Services to generate unsolicited email advertisements or spam;</li>
    <li>Use any automatic or manual process to search or harvest information from the Site or Services, unless otherwise as permitted in our API or available as a function provided by us (i.e. a search tool), or to interfere in any way with the proper functioning of the Site and Services; </li>
    <li>Use the Services in any manner which could disable, overburden, damage, or impair the Site or Mobile Apps or interfere with any other party’s use and enjoyment of the Site or Mobile Apps; or</li>
    <li>Co-brand our Services without the express prior written permission of our authorized representative. For purposes of these Terms of Service, “co-branding” means to display a name, logo, trademark, or other means of attribution or identification of the other party in any manner that is not expressly authorized.</li>
    </ul>


    <p>You agree to cooperate with us in causing any of the prohibited activities set forth in this section to immediately cease. </p>
    

    <h4>Trademarks</h4>


    <p>Our company name and all related logos, product and service names, designs and slogans are our trademarks or the trademarks of our affiliates or licensors. You must not use such marks without our prior written permission or as otherwise set out in these Terms of Service. </p>


    <h4>Our proprietary information</h4>


    <p>The material, content, and Services accessible from this Site, including, but not limited to, the API (if any), and any other website owned, operated, licensed, or otherwise controlled by us (the “Content”) is our proprietary information or the proprietary information of the party that provided or licensed the Content to us, whereby we or such providing party retain(s) all right, title, and interest in the Content. If we offer an API, our rights apply to that API as well as all output and executables of the API, excluding any software components developed by you which do not themselves incorporate the API. Accordingly, the Content may not be copied, decompiled, reverse engineered, disassembled, transferred, distributed, republished, resold, sublicensed, uploaded, posted, used to create derivative works, or transmitted in any way without our prior written consent or the prior written consent of the Content owner, except that you may print out a copy of the Content solely for your personal use. In doing so, you may not remove or alter, or cause to be removed or altered, any copyright, trademark, trade name, service mark, or any other proprietary notice or legend appearing on any of the Content. Modification or use of the Content except as expressly provided in these Terms of Service violates our intellectual property rights. Neither title nor intellectual property rights are transferred to you by access to this Site. Notwithstanding this provision, users of our Services and the Site are not permitted to use the Content as a component of, or basis for, a database prepared for use by the said user or any third party. Furthermore, all copies of Content, whether in print or electronic form, must incorporate all of our (or our third-party’s) copyright and/or other intellectual property rights notices.</p>


    <h4>Your proprietary information</h4>


    <p>We agree that we have no rights to the data, documents, information or material that you submit in the course of using the Site and Services ("Submitted Content"). When submitting data, you, and not us, shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all Submitted Content. We shall not be responsible or liable for the deletion, correction, destruction, damage, loss, or failure to store any Submitted Content. By using the Services, each user represents and warrants to us that it has the legal right to submit the Submitted Content. You agree to indemnify, defend and hold us harmless from any liability caused by your submission of Submitted Content in violation of a third party’s copyright or other rights of ownership. We reserve the right to withhold, remove and/or discard Submitted Content without notice for any breach, including without limitation, non-payment of fees. In addition, we reserve the right to withhold, remove and/or discard any Submitted Content submitted to us, which, in our sole opinion, is pornographic or otherwise in violation of our internal policies for publishable material. WE MAKE NO REPRESENTATION OR WARRANTY THAT ANY DATA SUBMITTED TO US WILL BE RETAINED FOR ANY PARTICULAR PERIOD OF TIME, AND YOU AGREE TO MAINTAIN ON YOUR SERVERS DUPLICATE COPIES OF ALL SUBMITTED CONTENT.</p>


    <h4>User contributions</h4>


    <p>You agree not to post or store on the Site any software, information, data, databases, music, audio, video or audiovisual files, photographs, images, documents, text, digital files or other material ("Material") that violates or infringes anyone's intellectual-property rights (including copyrights, trademarks, trade secrets, patents, publicity rights or (to the extent protectable) confidential ideas) or that violates U.S. law or that is obscene, obscene as to minors, child pornography, defamatory, racist, lewd, lascivious, filthy, excessively violent, harassing, or otherwise objectionable.  </p>


    <p>By posting Material to this Site, you grant us a perpetual, irrevocable, nonexclusive, royalty-free worldwide license to reproduce, adapt, distribute, perform (either publicly or by digital audio transmission) or publicly display all or any portion of the Material on the Site (though we will obtain your permission before doing so with any Material that is not publically available on our Site).  You further represent and warrant that you own all rights, titles, and interests to such Material in full and without restrictions.</p>


    <p>You expressly agree that we may remove, disable, or restrict access to or the availability of any Material from the Site (including, but not limited to, Material that you have posted or stored) that we believe, in good faith and in our sole discretion, to violate the Terms of Service (whether or not we are actually correct in our assessment) or that is the subject of a notification duly sent to us pursuant to the Digital Millennium Copyright Act.  If you believe that we have acted mistakenly with respect to certain material, you may contact us using one of the contact methods provided at the end of these Terms of Service, in which case we may investigate the matter further, though we retain full right and sole discretion as to whether or not to remove the material.   Under no circumstances may we be held liable for removing, disabling, or restricting access to or the availability of Material.  The provisions of this section are intended to implement these Terms of Service and are not intended to impose a contractual obligation on us to undertake, or refrain from undertaking any particular course of conduct.</p>


    <h4>Termination</h4>


    <p>We reserve the right to terminate these Terms of Service with immediate effect at any time and for any reason. Expiration or termination (for any reason) of these Terms of Service, shall not affect any accrued rights or liabilities which either party may then have nor shall it affect any clause which is expressly or by implication intended to continue in force after expiration or termination. </p>


    <h4>Service updates</h4>


    <p>The Site and Services may need updating from time to time. These updates may temporarily disrupt use of our Services and are designed to improve, enhance, and further develop our Services and may take the form of bug fixes, enhanced functions, new Service offerings, and updated Services. You agree to receive such updates as part of your use of the Services.</p>


    <h4>Links</h4>


    <p>Our Site or Mobile Apps may contain links to other sites, which are not maintained by, or related to, us. Links to such sites are provided as a service to users and are not sponsored by or affiliated with us. We have not reviewed any or all of such sites and are not responsible for the content of those sites. We are not responsible for webcasting or any other form of transmission received from any linked site. Links are to be used and third-party sites accessed at the user’s own risk, and we make no representations or warranties about the content, completeness or accuracy of these links or the sites hyper-linked to our Site or Mobile Apps. We provide links as a convenience, and the inclusion of any link to a third-party site does not necessarily imply endorsement by us of that site or any association with its operators. </p>
    


    <h4>Geographic restrictions</h4>


    <p>The owner of the Site is based in the United States. We provide this Site for use only by persons located in the United States. We make no claims that the Site or any of its content is accessible or appropriate outside of the United States. Access to the Site may not be legal by certain persons or in certain countries. If you access the Site from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.</p>


    <h4>Representations; Warranties; Disclaimer</h4>


    <p>You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for accuracy of data input and output, and for maintaining a means external to this Site for any reconstruction of any lost data. We do not assume any responsibility or risk for your use of the Internet.</p>


    <p>You represent and warrant that: (i) you have full power and authority to enter into these Terms of Service; (ii) you are the owner of, or are legally authorized to act on behalf of the owner of, all Submitted Content; (iii) you are the editorial and technical decision maker in relation to the Submitted Content for which the Services are requested and implemented and that you have control over the way in which the Services are implemented for your specific needs; (iv) we have never previously terminated or otherwise disabled an account created by you with us due to your breach of these Terms of Service or another agreement with us or due to impermissible activity; (vi) entering into or performing under these Terms of Service will not violate any agreement you have with a third-party or any third-party rights; and (vii) all of the information provided by you to us is current and correct.</p>

    <p>YOUR USE OF THIS SITE IS AT YOUR OWN RISK. THE SERVICES ARE PROVIDED “AS IS” AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED. WE DISCLAIM ALL WARRANTIES, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT. WE DO NOT WARRANT THAT THE FUNCTIONS OR CONTENT CONTAINED IN THIS SITE OR OUR MOBILE APPS WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THIS SITE, OUR MOBILE APPS, OR THE SERVER THAT MAKES OUR SERVICES AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE DO NOT WARRANT OR MAKE ANY REPRESENTATION REGARDING USE, OR THE RESULT OF USE, OF THE SERVICES IN TERMS OF ACCURACY, RELIABILITY, OR OTHERWISE. THE SERVICES MAY INCLUDE TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS, AND WE MAY MAKE CHANGES OR IMPROVEMENTS AT ANY TIME. YOU, AND NOT US, ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION IN THE EVENT OF ANY LOSS OR DAMAGE TO YOU OR YOUR PROPERTY ARISING FROM THE USE OF OUR SERVICES OR CONTENT. </p>


    <p>All of the information in our Site or Mobile Apps, whether historical in nature or forward-looking, speaks only as of the date the information is posted on this Site or Mobile Apps, and we do not undertake any obligation to update such information after it is posted or to remove such information from this Site or Mobile Apps if it is not, or is no longer accurate or complete.</p>


    <h4>Limitation of liability</h4>


    <p>WE, OUR SUBSIDIARIES, AFFILIATES, LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS WILL NOT BE LIABLE FOR ANY INCIDENTAL, INDIRECT, PUNITIVE, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR OTHER DAMAGES, INCLUDING LOSS OF REVENUE OR INCOME, PAIN AND SUFFERING, EMOTIONAL DISTRESS, OR SIMILAR DAMAGES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL OUR COLLECTIVE LIABILITY AND THE COLLECTIVE LIABILITY OF OUR SUBSIDIARIES, AFFILIATES, LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS TO ANY PARTY (REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE) EXCEED THE GREATER OF USD$100 OR FEES PAID FOR THE 6-MONTH PERIOD PRIOR TO THE EVENT GIVING RISE TO SUCH LIABILITY, IF ANY. IN ALL CASES, WE (AND OUR SUPPLIERS AND DISTRIBUTORS) WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT REASONABLY FORESEEABLE. Each party acknowledges that the other party has agreed to these Terms of Service in reliance on the limitation of liability stated herein and that those limitations are an essential basis of the bargain between the parties.</p>


    <h4>Indemnity</h4>


    <p>You will indemnify and hold us, our subsidiaries, affiliates, licensors, content providers, service providers, employees, agents, officers, directors, and contractors (the “Indemnified Parties”) harmless from any claims or liabilities arising out of or related to any Submitted Content, breach of these Terms of Service by you, or your use of the Services. You agree that the Indemnified Parties will have no liability in connection with any such breach, Submitted Content, or unauthorized use, and you agree to indemnify any and all resulting loss, damages, judgments, awards, costs, expenses, and attorneys’ fees of the Indemnified Parties in connection therewith. </p>


    <h4>Security</h4>


    <p>You are prohibited from using any Services or facilities provided in connection with this Site to compromise security or tamper with system resources and/or accounts. The use or distribution of tools designed for compromising security (e.g., password guessing programs, cracking tools or network probing tools) is strictly prohibited. If you become involved in any violation of system security, we reserve the right to release your details to system administrators at other sites in order to assist them in resolving security incidents. We reserve the right to investigate suspected violations of these Terms of Service.</p>


    <p>We reserve the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity of anyone posting any e-mail messages, or publishing or otherwise making available any materials that are believed to violate these Terms of Service. BY ACCEPTING THIS AGREEMENT, YOU HOLD US HARMLESS AND WAIVE ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY US DURING OR AS A RESULT OF OUR INVESTIGATIONS AND/OR FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY US OR BY LAW ENFORCEMENT AUTHORITIES.</p>


    <h4>No waiver</h4>


    <p>No delay or failure by us to enforce any of these Terms of Service shall constitute a waiver of any of our rights under these Terms of Service. Neither the receipt of any funds by us nor the reliance of any person on our actions shall be deemed to constitute a waiver of any part of these Terms of Service. Only a specific, written waiver signed by our authorized representative shall have any legal effect.</p>


    <h4>Severability</h4>


    <p>If any clause or provision set forth in this Terms of Service statement is determined to be illegal, invalid or unenforceable under present or future law, the clause or provision shall be deemed to be deleted without affecting the enforceability of all remaining clauses or provisions.</p>


    <h4>Export control and restricted countries</h4>


    <p>You may not use, export, re-export, import, or transfer the Services except as authorized by United States law, the laws of the jurisdiction in which you obtained the Services, and any other applicable laws. In particular, but without limitation, the Services may not be exported or re-exported: (a) into any United States embargoed countries; or (b) to anyone on the U.S. Treasury Department’s list of Specially Designated Nationals or the U.S. Department of Commerce’s Denied Person’s List or Entity List. By using our Service, you represent and warrant that (i) neither you nor your listed residence is located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties. You will not use the Services for any purposes prohibited by U.S. or international laws.</p>


    <h4>Governing law; Jurisdiction; Disputes</h4>


    <p>These Terms of Service and any disputes arising under or related to these Terms of Service and/or our Privacy Policy will be governed by U.S. federal law and the laws of the State of California, without reference to its conflict of law principles. You agree to the personal jurisdiction by and venue in the state and federal courts in California, and waive any objection to such jurisdiction or venue. No recovery may be sought or received for damages other than out-of-pocket expenses, except that the prevailing party will be entitled to costs and attorneys’ fees. Any claim under these Terms of Service must be brought within one (1) year after the cause of action arises, or such claim or cause of action is barred. In the event of any controversy or dispute between the parties arising out of or in connection with your use of this Site or our Mobile Apps, the parties shall attempt, promptly and in good faith, to resolve any such dispute directly. If the parties are unable to resolve any such dispute within a reasonable time (not to exceed thirty (30) days), then either party may submit such controversy or dispute to mediation. If the dispute cannot be resolved through mediation, then the parties shall be free to pursue any right or remedy available to them under applicable law.  </p>


    <h4>Feedback and information you send to us</h4>


    <p>We may provide you with the ability to contact us through email addresses, contact forms, or other means. To the extent that you do so and send along any ideas, input, feedback, or anything of a similar nature, you acknowledge that any such ideas, input, feedback, or otherwise shall be deemed to be non-confidential and we shall be free to use such information on an unrestricted basis.</p>


    <h4>Miscellaneous</h4>


    <p>Both parties shall be independent contractors and these Terms of Service do not create an agency, partnership, or joint venture. Unless stated otherwise in these Terms of Service, these Terms of Service do not create any third-party beneficiary rights. You may not assign your rights and obligations under these Terms of Service to any third party, and any purported attempt to do so shall be null and void. We may freely assign our rights and obligations under these Terms of Service.</p>


    <p>In addition to any excuse provided by applicable law, we shall be excused from liability for non-delivery or delay in delivery or availability of Services available through our Site or Mobile Apps arising from any event beyond our reasonable control, whether or not foreseeable by either party, including, but not limited to, labor disturbance, war, fire, accident, adverse weather, governmental act or regulation, and other causes or events, whether or not similar to those which are enumerated above.</p>


    <p>This English-language Terms of Service statement is our official agreement with users of this Site. In case of any inconsistency between this English-language Terms of Service statement and its translation into another language, this English-language document shall control.</p>
            
    <h4>Changes to these terms</h4>


    <p>We reserve the right, in our sole discretion, to modify, alter, or otherwise change these Terms of Service at any time. We will provide notice of such change on this Site or Mobile Apps. Please review the Terms of Service and/or additional terms periodically for changes. Your continued use of our Services constitutes your acceptance of and agreement to be bound by these changes without limitation, qualification or change. If, at any time, you determine that you do not accept these changes, you must stop using our Services.</p>


    <h4>How can you contact us? </h4>


    <p>If you have any questions about these Terms of Service or our Services, please contact us via the following:</p>
    <ul><li>By email at legal@WaitQue.com</li></ul>        
  </div>
</div>