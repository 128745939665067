import { Component } from "@angular/core";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'privacy-wrapper',
  template: '<app-privacy></app-privacy>'
})
export class PrivacyWrapperComponent {
  constructor(title: Title) {
    title.setTitle('WaitQue - Privacy');
  }
}