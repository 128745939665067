import { Component } from "@angular/core";
import { AuthService } from 'src/shared/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'sign-up-confirmation',
  templateUrl: 'sign-up-confirmation.component.html',
  styleUrls: [
    'sign-up-confirmation.component.scss'
  ]
})
export class SignUpConfirmationComponent {
  code: string;
  loading = false;
  error: string;
  confirmed = false;

  constructor(private authService: AuthService, private router: Router, private currentRoute: ActivatedRoute) {}

  confirm() {
    this.loading = true;
    this.authService.confirm(this.currentRoute.snapshot.queryParams['email'], this.code).then(result => {
      this.confirmed = true;

      setTimeout(() => {
        this.router.navigate(['/auth/login']);
      }, 2000);
    }).catch(err => {
      this.loading = false;

      if (err.code === 'CodeMismatchException') {
        this.error = err.message;
      } else {
        this.error = 'Oops, an unexpected error occurred verifying your account, please try again later.'
      }
    })
  }
}
