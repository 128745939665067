import { Component } from '@angular/core';

@Component({
  selector: 'button[primary], button[secondary]',
  templateUrl: 'button.component.html',
  styleUrls: [
    'button.component.scss'
  ]
})
export class ButtonComponent {}
