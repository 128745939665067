<form #confirmationForm="ngForm" class="form" *ngIf="!loading && !confirmed">
  <wq-message title="Verify Account" status="info" *ngIf="!error">
    Please verify your email address by entering the code emailed to you!
  </wq-message>
  <wq-message title="Verification Failed" status="error" *ngIf="error">
    {{ error }}
  </wq-message>
  <wq-textbox title="Code" [(ngModel)]="code" name="code" [required]="true"></wq-textbox>
  <div class="button-container">
    <button primary (click)="confirm()" [disabled]="confirmationForm.form.invalid">Confirm Account</button>
  </div>
</form>
<wq-message status="success" title="Account Confirmed" *ngIf="confirmed">
  Your account has been successfully confirmed, you will be redirected to login to WaitQue!
</wq-message>
<app-loader *ngIf="loading"></app-loader>
