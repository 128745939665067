import { Component } from "@angular/core";
import { AuthService } from 'src/shared/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'reset-password-confirmation',
  templateUrl: 'reset-password-confirmation.component.html',
  styleUrls: [
    'reset-password-confirmation.component.scss'
  ]
})
export class ResetPasswordConfirmationComponent {
  code: string;
  password: string;
  loading = false;
  error: string;
  reset: boolean;

  constructor(private authService: AuthService, private router: Router, private currentRoute: ActivatedRoute) {}

  resetPassword() {
    this.loading = true;
    this.authService.resetPasswordConfirm(this.currentRoute.snapshot.queryParams['email'], this.code, this.password).then(result => {
      this.reset = true;

      setTimeout(() => {
        this.router.navigate(['/auth/login']);
      }, 2000);
    }).catch(err => {
      this.loading = false;

      if (['InvalidParameterException','CodeMismatchException'].includes(err.code)) {
        this.error = err.message;
      } else {
        this.error = 'Oops, an unexpected error occurred verifying your account, please try again later.'
      }
    })
  }
}
