<wq-card title="Setup Business">
  <wq-message title="First Setup Your Business" status="info" *ngIf="!error">
    In order to proceed you must first setup your business!
  </wq-message>
  <wq-message title="Business Update Failed" *ngIf="error" status="error">
    {{ error }}
  </wq-message>
  <form #businessForm="ngForm" class="form" *ngIf="!loading">
    <business-setup [business]="business"></business-setup>
    <div class="button-container">
      <button primary (click)="createBusiness()" [disabled]="businessForm.form.invalid">Create Business</button>
    </div>
  </form>
  <app-loader *ngIf="loading"></app-loader>
</wq-card>