import { Injectable } from "@angular/core";

declare let ga: any;

@Injectable({
  providedIn: 'root'
})
export class GaService {
  trackPageView(path: string) {
    if (this.getGa()) {
      this.getGa().set('page', path);
      this.getGa().send('pageview');
    }
  }

  private getGa() {
    if (ga) {
      return ga.getAll()[0];
    }

    return null;
  }
}
