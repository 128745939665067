import { Component, Input } from "@angular/core";

@Component({
  selector: 'option[wq-dropdown-item]',
  templateUrl: 'dropdown-item.component.html',
  styleUrls: [
    'dropdown-item.component.scss'
  ]
})
export class DropdownItemComponent {
  @Input()
  name: string;

  @Input()
  value: any;
}