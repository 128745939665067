<div class="main" (swipeleft)="swipeLeft()" (swiperight)="swipeRight()">
    <div class="img-container">
        <img class="image" [src]="content[currentIndex]?.src">
    </div>
    <div class="text-container">
        {{content[currentIndex]?.text}}
    </div>
</div>
<div class="step-dots">
    <span *ngFor="let cssClass of cssClassArray" [ngClass]="cssClass.class" (click)="setCurrentIndex(cssClass.index)"></span>
</div>