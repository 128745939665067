import { Component, OnInit } from '@angular/core';
import { MeService } from '../me.service';

@Component({
  selector: 'portal',
  templateUrl: 'portal.component.html',
  styleUrls: [
    'portal.component.scss'
  ]
})
export class PortalComponent implements OnInit {
  me;

  constructor(private meService: MeService) {}

  ngOnInit() {
    this.meService.getMe().subscribe(me => {
      this.me = me;
    });
  }
}
