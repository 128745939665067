import { Component } from "@angular/core";
import { Faq } from '../faq';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import supportItems from '../../assets/faq.json';

@Component({
  selector: 'support',
  templateUrl: 'support.component.html',
  styleUrls: [
    'support.component.scss'
  ]
})
export class SupportComponent {
  faChevronRight = faChevronRight;
  faChevronDown = faChevronDown;

  faqs: Faq[];
  
  ngOnInit() {
    console.log(supportItems);
    this.faqs = supportItems.faqs;
  }
}