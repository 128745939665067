<div *ngIf="loading">
  <app-loader></app-loader>
</div>
<form #loginForm="ngForm" class="form" *ngIf="!loading">
  <wq-message title="Login Failed" status="error" *ngIf="error">
    {{ error }}
  </wq-message>

  <wq-textbox title="Email Address" name="email" [(ngModel)]="email" [required]="true"></wq-textbox>
  <wq-textbox title="Password" type="password" name="password" [(ngModel)]="password" [required]="true"></wq-textbox>

  <div class="button-container">
    <button primary (click)="login()" [disabled]="loginForm.form.invalid">Login</button>
    <button secondary [routerLink]="['/auth', 'sign-up']">Sign Up</button>
  </div>

  <div class="forgot-password">
    <a wq-link [routerLink]="['/auth', 'reset-password']">Forgot Your Password</a>
  </div>
</form>
