<div class="main">
    <app-step-dots currentStep="1"></app-step-dots>
    <div class="title">
        How should we let you know when it’s your turn?
    </div>
    <div class="error-container" *ngIf="error">
        <div class="error-icon-container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red" width="100px" height="100px"><path d="M0 0h24v24H0z" fill="none"/><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"/></svg>
        </div>
        <div class="error">
            {{ error }}
        </div>
    </div>
    <div class="field-name">
        Contact Preference
    </div>
    <div class="input-container">
        <select class="input" [(ngModel)]="customer.contactPreference" (change)="checkPhoneError(); checkEmailError();">
            <option *ngFor="let o of contactPreferenceOptions" [ngValue]="o.value">
                {{o.name}}
            </option>
        </select>
    </div>
    <div class="input-error">
        
    </div>
    <div class="field-name">
        Phone Number <span class="optional" *ngIf="customer.contactPreference == 'EMAIL'">(optional)</span>
    </div>
    <div class="input-container">
        <input type="tel" class="input" [(ngModel)]="customer.phone" (keyup)="checkPhoneError()" autocomplete="tel"/>
    </div>
    <div class="input-error">
        {{phoneError}}
    </div>
    <div class="field-name">
        Email <span class="optional" *ngIf="customer.contactPreference != 'EMAIL'">(optional)</span>
    </div>
    <div class="input-container">
        <input type="email" class="input" [(ngModel)]="customer.email" (keyup)="checkEmailError()" autocomplete="email"/>
    </div>
    <div class="input-error">
        {{emailError}}
    </div>
    <div class="input-container promotional">
        <input type="checkbox" class="checkbox" [(ngModel)]="customer.acceptPromotionalOffers" name="acceptPromotionalOffers" id="acceptPromotionalOffers" /> <label for="acceptPromotionalOffers">I agree to receive occasional promotional offers.</label>
    </div>
    <div class="spacer">

    </div>
    <div class="button-container">
        <button class="next-button" (click)="onNext()">Next</button>
    </div>
    <div class="button-container">
        <button class="back-button" (click)="onBack()">Back</button>
    </div>
</div>

