import { NgModule } from '@angular/core';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignUpConfirmationComponent } from './sign-up-confirmation/sign-up-confirmation.component';
import { SharedModule } from 'src/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { AuthRoutingModule } from './auth-routing.module';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './auth/auth.component';
import { SignUpSuccessComponent } from './sign-up-success/sign-up-success.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordConfirmationComponent } from './reset-password-confirmation/reset-password-confirmation.component';

@NgModule({
  declarations: [
    AuthComponent,
    ResetPasswordComponent,
    ResetPasswordConfirmationComponent,
    LoginComponent,
    LogoutComponent,
    SignUpComponent,
    SignUpConfirmationComponent,
    SignUpSuccessComponent
  ],
  imports: [
    AuthRoutingModule,
    CommonModule,
    FormsModule,
    SharedModule
  ]
})
export class AuthModule {}
