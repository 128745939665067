import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LandingComponent } from './landing/landing.component';
import { ContactInfoOneComponent } from './contact-info-one/contact-info-one.component';
import { ContactInfoTwoComponent } from './contact-info-two/contact-info-two.component';
import { QuestionComponent } from './question/question.component';
import { CheckInConfirmComponent } from './check-in-confirm/check-in-confirm.component';
import { CheckInCompleteComponent } from './check-in-complete/check-in-complete.component';
import { HomeComponent } from './home/home.component';
import { WebComponent } from './web/web.component';
import { PrivacyWrapperComponent } from './privacy-wrapper/privacy-wrapper.component';
import { TermsWrapperComponent } from './terms-wrapper/terms-wrapper.component';
import { SupportWrapperComponent } from './support-wrapper/support-wrapper.component';
import { AuthGuard } from 'src/shared/auth.guard';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'auth', loadChildren: "../auth/auth.module#AuthModule"},
  {
    path: 'portal',
    canActivate: [ AuthGuard ],
    loadChildren: '../portal/portal.module#PortalModule'
  },
  {path: 'privacy', component: PrivacyWrapperComponent},
  {path: 'terms', component: TermsWrapperComponent},
  {path: 'support', component: SupportWrapperComponent},
  {
    path: ':businessId',
    component: WebComponent,
    children: [
      {path: '', component: LandingComponent},
      {path: 'contactInfoOne', component: ContactInfoOneComponent},
      {path: 'contactInfoTwo', component: ContactInfoTwoComponent},
      {path: 'question/:questionIndex', component: QuestionComponent},
      {path: 'checkInConfirm', component: CheckInConfirmComponent},
      {path: 'checkInComplete', component: CheckInCompleteComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
