<div *ngIf="loading">
  <app-loader></app-loader>
</div>
<form class="form" #signUpForm="ngForm" *ngIf="!loading">
  <wq-message title="Sign Up Failed" status="error" *ngIf="error">
    {{ error }}
  </wq-message>

  <wq-textbox title="Email Address" name="email" [(ngModel)]="email" [required]="true"></wq-textbox>
  <wq-textbox title="Password" name="password" type="password" [(ngModel)]="password" minLength="6" [required]="true"></wq-textbox>
  <wq-textbox title="First Name" name="firstName" [(ngModel)]="firstName" [required]="true"></wq-textbox>
  <wq-textbox title="Last Name" name="lastName" [(ngModel)]="lastName" [required]="true"></wq-textbox>

  <div class="button-container">
    <button primary (click)="signUp()" [disabled]="signUpForm.form.invalid">Sign Up</button>
    <button secondary [routerLink]="['/auth', 'login']">Login</button>
  </div>
</form>
