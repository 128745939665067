import { Component } from "@angular/core";
import { Router } from '@angular/router';
import { Business } from '../business';
import { BusinessService } from '../business.service';
import { chain } from 'lodash';

@Component({
  selector: 'business-create',
  templateUrl: 'business-create.component.html',
  styleUrls: [
    'business-create.component.scss'
  ]
})
export class BusinessCreateComponent {
  business = new Business();
  loading = false;
  error: string;

  constructor(private businessService: BusinessService, private router: Router) {}

  createBusiness() {
    this.loading = true;
    this.businessService.create(this.business).subscribe(() => {
      this.router.navigate(['portal']);
    }, err => {
      this.loading = false;
      if (err.status === 400) {
        this.error = chain(err.error).values().map('errors').flatten().value().join(', ');
      } else {
        this.error = 'An unexpected error occurred while creating your business, please try again later.'
      }
    });
  }
}