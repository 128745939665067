import { Component, OnInit } from '@angular/core';

import { StateService } from 'src/app/state.service';

@Component({
  selector: 'app-check-in-complete',
  templateUrl: './check-in-complete.component.html',
  styleUrls: ['./check-in-complete.component.scss']
})
export class CheckInCompleteComponent implements OnInit {
  contactPreference: String = '';

  constructor(private stateService: StateService) { }

  ngOnInit(): void {
    let contactPreferenceEnum = this.stateService.getCustomer().contactPreference;
    
    if(contactPreferenceEnum == 'EMAIL') {
      this.contactPreference = 'an email';
    } else if(contactPreferenceEnum == 'PHONE') {
      this.contactPreference = 'a phone call';
    } else if(contactPreferenceEnum == 'SMS') {
      this.contactPreference = 'a text message';
    }
  }
}
