import { Component, OnInit } from "@angular/core";
import { CheckInService } from '../check-in.service';
import { MeService } from '../me.service';
import { switchMap } from 'rxjs/operators';
import { CheckInStatus, CheckInResponse } from '../check-in';
import { filter } from 'lodash';
import moment from 'moment';

@Component({
  selector: 'today-status',
  templateUrl: 'today-status.component.html',
  styleUrls: [
    'today-status.component.scss'
  ]
})
export class TodayStatusComponent implements OnInit {
  checkInsToday = 0;
  currentlyWaiting = 0;
  currentlyInside = 0;
  noShowsToday = 0;
  leftToday = 0;
  rejectedToday = 0;
  loading = false;
  private static today = moment().startOf('day');

  constructor(private checkInService: CheckInService, private meService: MeService) {}

  ngOnInit() {
    this.loading = true;
    this.meService.getMe().pipe(switchMap(me => {
      return this.checkInService.getCheckIns(me.businessIds[0], 1, [ CheckInStatus.CheckedIn, CheckInStatus.Inside, CheckInStatus.Left, CheckInStatus.NoShow, CheckInStatus.Rejected ]);
    })).subscribe(checkIns => {
      this.loading = false;
      this.checkInsToday = this.countStatus(checkIns, null);
      this.currentlyWaiting = this.countStatus(checkIns, CheckInStatus.CheckedIn);
      this.currentlyInside = this.countStatus(checkIns, CheckInStatus.Inside);
      this.noShowsToday = this.countStatus(checkIns, CheckInStatus.NoShow);
      this.leftToday = this.countStatus(checkIns, CheckInStatus.Left);
      this.rejectedToday = this.countStatus(checkIns, CheckInStatus.Rejected);
    }, (error) => {

    }, () => {

    });
  }

  private countStatus(checkIns: CheckInResponse[], status: CheckInStatus) {
    return filter(checkIns, checkIn => {
      return (status === null || checkIn.status === status) && moment(checkIn.checkinDateTime).startOf('day').isSame(TodayStatusComponent.today);
    }).length;
  }
}