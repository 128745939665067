export class CheckInResponse {
  id: string;
  customerId: string;
  customer: CustomerResponse;
  businessId: string;
  partySize: number;
  tosAcceptanceDateTime: string;
  checkinDateTime: string;
  allowedInDateTime: string;
  leftDateTime: string;
  noShowDateTime: string;
  rejectDateTime: string;
  status: CheckInStatus;

}

export class CustomerResponse {
  firstName: string;
  lastName: string;
}

export enum CheckInStatus {
  CheckedIn = 'checked-in',
  Inside = 'inside',
  Left = 'left',
  Rejected = 'rejected',
  NoShow = 'no-show',
  Incomplete = 'incomplete'
}