import { Routes, RouterModule } from "@angular/router";
import { NgModule } from '@angular/core';
import { PortalComponent } from './portal/portal.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BusinessSetupComponent } from './business-setup/business-setup.component';
import { BusinessCreateComponent } from './business-create/business-create.component';
import { BusinessUpdateComponent } from './business-update/business-update.component';
import { CheckInsComponent } from './check-ins/check-ins.component';

const routes: Routes = [
  {
    path: '',
    component: PortalComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'businessCreate',
        component: BusinessCreateComponent
      },
      {
        path: 'businessUpdate',
        component: BusinessUpdateComponent
      },
      {
        path: 'checkIns',
        component: CheckInsComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      }
    ]
  }
]

@NgModule({
  imports: [ RouterModule.forChild(routes) ],
  exports: [ RouterModule ]
})
export class PortalRoutingModule {}
