import { Component, OnInit } from '@angular/core';
import { ContactUsService } from '../contact-us.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  showPrivacyModal: boolean = false;
  showTosModal: boolean = false;
  contactUsRequest: {
    email: string,
    name: string,
    subject: string,
    message: string
  } = {
    email: null,
    name: null,
    subject: null,
    message: null
  };

  messageSent: boolean = false;
  errorsActivated: boolean = false;
  emailError: string = '';
  nameError: string = '';
  subjectError: string = '';
  messageError: string = '';
  recaptchaError: string = '';

  private recaptcha: string = null;

  constructor(private contactUsService: ContactUsService) {
  }

  ngOnInit(): void {
  }

  resolved(captchaResponse: string) {
    this.recaptcha = captchaResponse;
    this.checkRecaptchaError();
  }

  onSend() {
    this.errorsActivated = true;

    this.checkEmailError();
    this.checkNameError();
    this.checkSubjectError();
    this.checkMessageError();
    this.checkRecaptchaError();

    if(this.emailError == '' && this.nameError == '' && this.subjectError == '' && this.messageError == '' && this.recaptchaError == '') {
      this.contactUsService.contactUs({
        email: this.contactUsRequest.email,
        name: this.contactUsRequest.name,
        body: this.contactUsRequest.message,
        subject: this.contactUsRequest.subject,
        recaptcha: this.recaptcha
      }).subscribe(() => {
        this.messageSent = true;
      });
    }
  }

  checkEmailError() {
    this.emailError = '';

    if((!this.contactUsRequest.email || this.contactUsRequest.email == '') && this.errorsActivated) {
      this.emailError = "Email is required";
    } else if(!this.contactUsRequest.email.match(/^.+@.+?\..+$/) && this.errorsActivated) {
      this.emailError = "Please enter a valid email address";
    }
  }

  checkNameError() {
    this.nameError = '';

    if((!this.contactUsRequest.name || this.contactUsRequest.name == '') && this.errorsActivated) {
      this.nameError = "Name is required";
    }
  }

  checkSubjectError() {
    this.subjectError = '';

    if((!this.contactUsRequest.subject || this.contactUsRequest.subject == '') && this.errorsActivated) {
      this.subjectError = "Subject is required";
    }
  }

  checkMessageError() {
    this.messageError = '';

    if((!this.contactUsRequest.message || this.contactUsRequest.message == '') && this.errorsActivated) {
      this.messageError = "A message is required";
    }
  }

  checkRecaptchaError() {
    this.recaptchaError = '';

    if((!this.recaptcha || this.recaptcha == '') && this.errorsActivated) {
      this.recaptchaError = "Please confirm that you're not a robot";
    }
  }

  openPrivacyModal() {
    this.showPrivacyModal = true;
  }

  privacyCloseClicked(close: boolean) {
    this.showPrivacyModal = false;
  }

  openTosModal() {
    this.showTosModal = true;
  }

  tosCloseClicked(close: boolean) {
    this.showTosModal = false;
  }
}
