import { Component } from "@angular/core";
import { AuthService } from 'src/shared/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'sign-up',
  templateUrl: 'sign-up.component.html',
  styleUrls: [
    'sign-up.component.scss'
  ]
})
export class SignUpComponent {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  loading = false;
  error: string;

  constructor(private authService: AuthService, private router: Router) {}

  signUp() {
    this.loading = true;

    this.authService.signUp(this.email, this.firstName, this.lastName, this.password).then(result => {
      this.router.navigate(['/auth/sign-up-confirmation'], {
        queryParams: {
            email: this.email
        }
      });
    }).catch(err => {
      this.loading = false;

      if (['InvalidParameterException', 'UsernameExistsException'].includes(err.code)) {
        this.error = err.message;
      } else {
        this.error = 'Oops, an unexpected error occurred signing up, please try again later.'
      }
    });
  }
}
