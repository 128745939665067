import { Component } from "@angular/core";
import { AuthService } from 'src/shared/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'reset-password',
  templateUrl: 'reset-password.component.html',
  styleUrls: [
    'reset-password.component.scss'
  ]
})
export class ResetPasswordComponent {
  email: string;
  loading = false;

  constructor(private authService: AuthService, private router: Router) {}

  resetPassword() {
    this.loading = true;
    this.authService.resetPassword(this.email).then(result => {
      this.router.navigate(['/auth/reset-password-confirmation'], {
        queryParams: {
            email: this.email
        }
      });
    });
  }
}
