import { Component, Input, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'wq-textbox',
  templateUrl: 'textbox.component.html',
  styleUrls: [
    'textbox.component.scss'
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextboxComponent),
      multi: true
    }
  ]
})
export class TextboxComponent implements ControlValueAccessor {
  @Input()
  title: string;

  @Input()
  required: boolean;

  @Input()
  type: 'text' | 'password' | 'number' = 'text';

  @Input()
  minLength = 0;

  value;

  onChange: (_: any) => void = (_: any) => {};

  onTouched: () => void = () => {};

  updateChanges() {
    this.onChange(this.parseValue(this.value));
  }

  writeValue(obj: any): void {
    this.value = this.parseValue(obj);
    this.updateChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private parseValue(value: any) {
    if (this.type === 'number' && value) {
      return Number(value);
    }

    return value;
  }
}
