import { Component, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  content: any[] = [
    {
      src: 'assets/screen_caps/1.jpg',
      text: 'iOS and Android app: Sign-up your business directly from the app'
    },
    {
      src: 'assets/screen_caps/4.jpg',
      text: 'Receive the check-in link and QR code as a printable document'
    },
    {
      src: 'assets/screen_caps/5.jpg',
      text: 'Web interface: When customers scan the QR code or navigate to the link on the sign, they land here to begin checking-in'
    },
    {
      src: 'assets/screen_caps/6.jpg',
      text: 'Web interface: Customers enter basic information about who they are'
    },
    {
      src: 'assets/screen_caps/7.jpg',
      text: 'Web interface: Customers enter contact information and how they preferred to be contacted when it is their turn'
    },
    {
      src: 'assets/screen_caps/10.jpg',
      text: 'Web interface: Customer is checked-in!'
    },
    {
      src: 'assets/screen_caps/11.jpg',
      text: 'iOS and Android app: You will receive push notifications when a customer checks in and you can manage check-ins from the simple dashboard in the app'
    }
  ];
  currentIndex: number = 0;
  private sourceSubscription: Subscription;

  cssClassArray = [];

  constructor() {
    this.generateCssClassArray();

    this.setupTimer();
  }

  private generateCssClassArray() {
    this.cssClassArray = [];
    for(let i = 0; i < this.content.length; i++) {
      if(i == this.currentIndex) {
        this.cssClassArray.push({
          class: 'selected-dot',
          index: i
        });
      } else {
        this.cssClassArray.push({
          class: 'dot',
          index: i
        });
      }
    }
  }

  private setupTimer() {
    if (this.sourceSubscription) {
      this.sourceSubscription.unsubscribe();
    }

    this.sourceSubscription = timer(5000, 5000).subscribe(val => {
      this.currentIndex++;

      if(this.currentIndex >= this.content.length) {
        this.currentIndex = 0;
      }

      this.generateCssClassArray();
    });
  }

  setCurrentIndex(newIndex: number) {
    this.setupTimer();
    
    this.currentIndex = newIndex;
    this.generateCssClassArray();
  }

  swipeLeft() {
    this.setupTimer();
    
    this.currentIndex++;
    
    if(this.currentIndex >= this.content.length) {
      this.currentIndex = 0;
    }

    this.generateCssClassArray();
  }

  swipeRight() {
    this.setupTimer();

    this.currentIndex--;
    
    if(this.currentIndex < 0) {
      this.currentIndex = this.content.length - 1;
    }

    this.generateCssClassArray();
  }

  ngOnInit(): void {
  }
}
