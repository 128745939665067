import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { StateService } from 'src/app/state.service';
import { Customer } from 'src/app/models/customer';
import { CheckIn } from 'src/app/models/checkin';

@Component({
  selector: 'app-contact-info-one',
  templateUrl: './contact-info-one.component.html',
  styleUrls: ['./contact-info-one.component.scss']
})
export class ContactInfoOneComponent implements OnInit {
  customer: Customer;
  checkin: CheckIn;

  errorsActivated: boolean = false;
  firstNameError: String = '';

  constructor(private stateService: StateService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.customer = this.stateService.getCustomer();
    this.checkin = this.stateService.getCheckIn();
  }

  onNext() {
    this.errorsActivated = true;
    this.checkFirstNameError();

    if(this.firstNameError == '') {
      this.router.navigate(["../contactInfoTwo"], { relativeTo: this.route });
    }
  }

  checkFirstNameError() {
    this.firstNameError = '';

    if((!this.customer.firstName || this.customer.firstName == '') && this.errorsActivated) {
      this.firstNameError = "First name is required";
    }
  }
}
