import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BusinessService } from '../business.service';
import { StateService } from '../state.service';
import { Business } from 'src/app/models/business';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  business: Business;

  constructor(private businessService: BusinessService, private stateService: StateService, private router: Router, private route: ActivatedRoute, private title: Title) { }

  ngOnInit(): void {
    let businessId = this.route.snapshot.paramMap.get("businessId");

    this.businessService.getBusiness(businessId).subscribe(business => {
      this.title.setTitle(`WaitQue - ${business.name}`);
      this.stateService.setBusiness(business);
      this.business = this.stateService.getBusiness();

      this.businessService.getQuestions(businessId).subscribe(questions => {
        this.stateService.setQuestions(questions);
      })
    });
  }

  onNext() {
    this.router.navigate(["contactInfoOne"], { relativeTo: this.route });
  }
}
