<div class="navbar">
  <div class="logo">
    <img src="assets/logo_140_green.png" height="70px" width="234px">
  </div>
  <div class="links">
    <div class="link" [routerLink]="['dashboard']" routerLinkActive="active">
      Dashboard
    </div>
    <div class="link" [routerLink]="['checkIns']" routerLinkActive="active">
      Check Ins
    </div>
    <div class="link" [routerLink]="['businessUpdate']" routerLinkActive="active">
      Manage Business
    </div>
  </div>
  <div class="logout" [routerLink]="['/auth/logout']">
    Logout
  </div>
</div>
<div class="body">
  <router-outlet></router-outlet>
</div>