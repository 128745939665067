import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MeService } from './me.service';
import { tap, shareReplay } from 'rxjs/operators';
import { Business } from './business';
import { pick } from 'lodash';
import { AuthService } from 'src/shared/auth.service';

@Injectable()
export class BusinessService {
  private business$: Observable<Business>;
  private static updateFields = [
    'logoUrl',
    'name',
    'addressLine1',
    'addressLine2',
    'city',
    'state',
    'zip',
    'phoneNumber',
    'websiteUrl',
    'typeOfBusiness',
    'capacity'
  ];

  constructor(private http: HttpClient, private meService: MeService, authService: AuthService) {
    authService.onLogout.subscribe(() => this.business$ = null);
  }

  create(business: Business): Observable<Business> {
    return this.http.post<Business>(`${environment.apiBaseUrl}/business`, business).pipe(
      tap(() => {
        return this.meService.clearMe();
      })
    );
  }

  get(businessId: String): Observable<Business> {
    if (!this.business$) {
      this.business$ = this.http.get<Business>(`${environment.apiBaseUrl}/business/${businessId}`).pipe(
        shareReplay({ bufferSize: 1, refCount:  true })
      );
    }

    return this.business$;
  }

  update(business: Business) {
    return this.http.patch(`${environment.apiBaseUrl}/business/${business.id}`, pick(business, BusinessService.updateFields)).pipe(
      tap(() => {
        this.business$ = null;
      })
    );
  }
}