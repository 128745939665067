<wq-card title="Check-Ins Today">
  <div class="count" *ngIf="!loading">
    {{ checkInsToday }}
  </div>
  <app-loader *ngIf="loading"></app-loader>
</wq-card>
<wq-card title="Currently Waiting">
  <div class="count" *ngIf="!loading">
    {{ currentlyWaiting }}
  </div>
  <app-loader *ngIf="loading"></app-loader>
</wq-card>
<wq-card title="Currently Inside">
  <div class="count" *ngIf="!loading">
    {{ currentlyInside }}
  </div>
  <app-loader *ngIf="loading"></app-loader>
</wq-card>
<wq-card title="Left Today">
  <div class="count" *ngIf="!loading">
    {{ leftToday }}
  </div>
  <app-loader *ngIf="loading"></app-loader>
</wq-card>
<wq-card title="Rejected Today">
  <div class="count" *ngIf="!loading">
    {{ rejectedToday }}
  </div>
  <app-loader *ngIf="loading"></app-loader>
</wq-card>
<wq-card title="No Shows Today">
  <div class="count" *ngIf="!loading">
    {{ noShowsToday }}
  </div>
  <app-loader *ngIf="loading"></app-loader>
</wq-card>