import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { Business } from 'src/app/models/business';
import { environment } from '../environments/environment';
import { Question } from 'src/app/models/question';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {
  constructor(private httpClient: HttpClient) { }

  getBusiness(businessId: string): Observable<Business> {
    return this.httpClient.get<Business>(`${environment.apiBaseUrl}/business/${businessId}`);
  }

  getQuestions(businessId: string): Observable<Question[]> {
    return this.httpClient.get<Question[]>(`${environment.apiBaseUrl}/business/${businessId}/questions`);
  }
}
