<div class="main" *ngIf="contentType == 'privacy'">
    <div class="title-container">
        <div class="title">Privacy Policy</div>
        <div class="close" (click)="onClose()">            
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#c0c0c0" width="24px" height="24px"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
        </div>
    </div>
    <div class="content">
        <app-privacy></app-privacy>
    </div>
</div>
<div class="main" *ngIf="contentType == 'tos'">
    <div class="title-container">
        <div class="title">Terms of Service</div>
        <div class="close" (click)="onClose()">            
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#c0c0c0" width="24px" height="24px"><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
        </div>
    </div>
    <div class="content">
        <app-terms></app-terms>
    </div>
</div>