import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { Business } from '../business';
import { BusinessService } from '../business.service';
import { MeService } from '../me.service';
import { switchMap } from 'rxjs/operators';
import { isEmpty, chain } from 'lodash';
import { throwError } from 'rxjs';

@Component({
  selector: 'business-update',
  templateUrl: 'business-update.component.html',
  styleUrls: [
    'business-update.component.scss'
  ]
})
export class BusinessUpdateComponent implements OnInit {
  business: Business;
  loading = false;
  error: string;

  constructor(private businessService: BusinessService, private router: Router, private meService: MeService) {}

  ngOnInit() {
    this.meService.getMe().pipe(
      switchMap(me => {
        if (isEmpty(me.businessIds)) {
          this.router.navigate(['portal', 'businessCreate']);
          return throwError('No Business');
        }

        return this.businessService.get(me.businessIds[0]);
      })
    ).subscribe(business => {
      this.business = business;
    })
  }

  updateBusiness() {
    this.loading = true;
    this.businessService.update(this.business).subscribe(() => {
      this.router.navigate(['portal']);
    }, err => {
      this.loading = false;
      if (err.status === 400) {
        this.error = chain(err.error).values().map('errors').flatten().value().join(', ');
      } else {
        this.error = 'An unexpected error occurred while creating your business, please try again later.'
      }
    });
  }
}