import { Component } from '@angular/core';

@Component({
  selector: 'sign-up-success',
  templateUrl: 'sign-up-success.component.html',
  styleUrls: [
    'sign-up-success.component.scss'
  ]
})
export class SignUpSuccessComponent {}
