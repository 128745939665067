import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, forkJoin, ReplaySubject } from 'rxjs';
import { switchMap, retry } from 'rxjs/operators'

import { StateService } from 'src/app/state.service';

import { environment } from '../environments/environment';
import { CheckIn } from 'src/app/models/checkin';
import { Customer } from 'src/app/models/customer';
import { Business } from 'src/app/models/business';
import { Answer } from 'src/app/models/answer';
import { chain, some } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CheckInService {
  private _answerQueue = [];
  private _checkInStatus = new ReplaySubject<any>(1);

  constructor(private stateService: StateService, private httpClient: HttpClient) { }

  get checkInStatus() {
    return this._checkInStatus.asObservable();
  }

  startCheckIn() {
    let business: Business = this.stateService.getBusiness();
    let customer: Customer = this.stateService.getCustomer();
    let checkIn: CheckIn = this.stateService.getCheckIn();

    checkIn.partySize = Number(checkIn.partySize);
    customer.phone = customer.phone.replace(/[^0-9]/g, "");

    return this.httpClient.post<Customer>(`${environment.apiBaseUrl}/customer`, customer).pipe(
      switchMap(newCustomer => {
        customer.id = newCustomer.id;

        checkIn.businessId = business.id;
        checkIn.customerId = customer.id;

        return this.httpClient.post<CheckIn>(`${environment.apiBaseUrl}/business/${checkIn.businessId}/checkIn`, checkIn);
    }), switchMap(newCheckIn => {
      checkIn.id = newCheckIn.id;
      return this.processAnswerQueue();
    })).subscribe(() => {
      this._checkInStatus.next(true);
    }, err => {
      if (err.status === 412) {
        return this._checkInStatus.error(err.error.message);
      }

      this._checkInStatus.error('Oops something went wrong. Please try checking in again.');
    });
  }

  answerQuestion(questionId: string, answerBool: boolean) {
    this._answerQueue.push({
      questionId: questionId,
      answerBool: answerBool,
      processed: false
    });

    return this.processAnswerQueue().toPromise();
  }

  completeCheckIn(): Observable<CheckIn> {
    let business: Business = this.stateService.getBusiness();
    let checkIn: CheckIn = this.stateService.getCheckIn();

    return this._checkInStatus.pipe(switchMap(() => {
      return this.processAnswerQueue();
    }), switchMap(() => {
      return this.httpClient.post<CheckIn>(`${environment.apiBaseUrl}/business/${business.id}/checkIn/${checkIn.id}/complete`, null);
    }));
  }

  hasUnprocessedAnswers(): boolean {
    return some(this._answerQueue, (answer) => !answer.processed);
  }

  private processAnswerQueue() {
    let business: Business = this.stateService.getBusiness();
    let checkIn: CheckIn = this.stateService.getCheckIn();

    if(checkIn.id && this.hasUnprocessedAnswers()) {
      return forkJoin(chain(this._answerQueue).filter(answer => !answer.processed).map(item => {
        let answer: Answer = {
          checkInId: checkIn.id,
          questionId: item.questionId,
          answerBool: item.answerBool
        };

        item.processed = true;
    
        return this.httpClient.post<Answer>(`${environment.apiBaseUrl}/business/${business.id}/checkIn/${checkIn.id}/answer`, answer).pipe(retry(2));
      }).value());
    }

    return of(null);
  }
}
