import { NgModule } from '@angular/core';
import { TextboxComponent } from './textbox/textbox.component';
import { FormsModule } from '@angular/forms';
import { AuthService } from './auth.service';
import { ButtonComponent } from './button/button.component';
import { LoaderComponent } from './loader/loader.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { AuthGuard } from './auth.guard';
import { CardComponent } from './card/card.component';
import { MessageComponent } from './message/message.component';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DropdownComponent } from './dropdown/dropdown.component';
import { DropdownItemComponent } from './dropdown/dropdown-item.component';
import { LinkComponent } from './link/link.component';
import { GaService } from './ga.service';

@NgModule({
  declarations: [
    ButtonComponent,
    CardComponent,
    DropdownComponent,
    DropdownItemComponent,
    LinkComponent,
    LoaderComponent,
    MessageComponent,
    TextboxComponent
  ],
  exports: [
    ButtonComponent,
    CardComponent,
    DropdownComponent,
    DropdownItemComponent,
    LinkComponent,
    LoaderComponent,
    MessageComponent,
    TextboxComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule
  ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    GaService
  ]
})
export class SharedModule {}
