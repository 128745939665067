export class Business {
  id: string;
  logoUrl: string;
  name: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: number;
  phoneNumber: string;
  websiteUrl: string;
  typeOfBusiness: string;
  capacity: number;
  openStatus: string;
}