import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { StateService } from 'src/app/state.service';
import { CheckInService } from 'src/app/check-in.service';
import { Customer } from 'src/app/models/customer';

@Component({
  selector: 'app-contact-info-two',
  templateUrl: './contact-info-two.component.html',
  styleUrls: ['./contact-info-two.component.scss']
})
export class ContactInfoTwoComponent implements OnInit {
  customer: Customer;
  contactPreferenceOptions = [
    {
      name: 'Send me a text message',
      value: 'SMS'
    },
    {
      name: 'Call me',
      value: 'PHONE'
    },
    {
      name: 'Send me an email',
      value: 'EMAIL'
    }
  ];

  errorsActivated: boolean = false;
  phoneError: String = '';
  emailError: String = '';
  error: string;

  constructor(private stateService: StateService, private checkInService: CheckInService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.customer = this.stateService.getCustomer();
    
    if(!this.customer.contactPreference) {
      this.customer.contactPreference = 'SMS';
    }

    this.formatPhone();
  }

  onNext() {
    this.errorsActivated = true;
    this.checkPhoneError();
    this.checkEmailError();

    if(this.phoneError == '' && this.emailError == '') {
      this.checkInService.startCheckIn();
      this.router.navigate(["../question", "0"], { relativeTo: this.route });
    }
  }

  onBack() {
    this.router.navigate(["../contactInfoOne"], { relativeTo: this.route });
  }

  checkPhoneError() {
    this.phoneError = '';
    this.formatPhone();

    if((!this.customer.phone || this.customer.phone == '') && this.customer.contactPreference != 'EMAIL' && this.errorsActivated) {
      this.phoneError = "Phone number is required";
    }

    if(this.customer.phone && this.customer.phone != '' && this.customer.phone.length < 10 && this.errorsActivated) {
      this.phoneError = "Please enter a 10 digit US phone number";
    }
  }

  private formatPhone() {
    this.customer.phone = this.customer.phone.replace(/[^0-9]/g, "");
    
    let substring1 = '';
    let substring2 = '';
    let substring3 = '';

    if(this.customer.phone.length > 6) {
      substring1 = this.customer.phone.substring(0, 3);
      substring2 = this.customer.phone.substring(3, 6);
      
      let lastIndex = this.customer.phone.length > 10 ? 10 : this.customer.phone.length;
      substring3 = this.customer.phone.substring(6, lastIndex);

      this.customer.phone = substring1 + "-" + substring2 + "-" + substring3;
    } else if(this.customer.phone.length > 3) {
      substring1 = this.customer.phone.substring(0, 3);
      substring2 = this.customer.phone.substring(3, this.customer.phone.length);

      this.customer.phone = substring1 + "-" + substring2;
    }
  }

  checkEmailError() {
    this.emailError = '';

    if((!this.customer.email || this.customer.email == '') && this.customer.contactPreference == 'EMAIL' && this.errorsActivated) {
      this.emailError = "Email is required";
    }

    if(this.customer.email && this.customer.email != '' && !this.customer.email.match(/^.+@.+?\..+$/) && this.errorsActivated) {
      this.emailError = "Please enter a valid email address";
    }
  }
}
