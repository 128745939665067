import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Me } from './me';
import { shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from 'src/shared/auth.service';

@Injectable()
export class MeService {
  private me$: Observable<Me>;

  constructor(private http: HttpClient, authService: AuthService) {
    authService.onLogout.subscribe(() => this.me$ = null);
  }

  getMe() {
    if (!this.me$) {
      this.me$ = this.http.get<Me>(`${environment.apiBaseUrl}/me`).pipe(shareReplay({ bufferSize: 1, refCount: true}));
    }

    return this.me$;
  }

  clearMe() {
    this.me$ = null;
  }
}
