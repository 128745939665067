import { Component, OnInit } from "@angular/core";
import { AuthService } from 'src/shared/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'logout',
  templateUrl: 'logout.component.html',
  styleUrls: [
    'logout.component.scss'
  ]
})
export class LogoutComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.authService.logout().then(() => {
      setTimeout(() => {
        this.router.navigate(['/auth/login']);
      }, 2000);
    });
  }
}
