import { Component } from "@angular/core";

@Component({
  selector: 'check-ins',
  templateUrl: 'check-ins.component.html',
  styleUrls: [
    'check-ins.component.scss'
  ]
})
export class CheckInsComponent {
  
}