import { Component, OnInit, Input } from "@angular/core";
import { Business } from '../business';
import { BusinessService } from '../business.service';
import { Router } from '@angular/router';
import { NgForm, ControlContainer } from '@angular/forms';
import typesOfBusiness from 'src/assets/types_of_business.json';

@Component({
  selector: 'business-setup',
  templateUrl: 'business-setup.component.html',
  styleUrls: [
    'business-setup.component.scss'
  ],
  viewProviders: [
    { provide: ControlContainer, useExisting: NgForm }
  ]
})
export class BusinessSetupComponent implements OnInit {
  @Input()
  business: Business;

  loading = false;

  typesOfBusiness: string[] = typesOfBusiness;
  stateList = [
    "AK",
    "AL",
    "AR",
    "AZ",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "IA",
    "ID",
    "IL",
    "IN",
    "KS",
    "KY",
    "LA",
    "MA",
    "MD",
    "ME",
    "MI",
    "MN",
    "MO",
    "MS",
    "MT",
    "NC",
    "ND",
    "NE",
    "NH",
    "NJ",
    "NM",
    "NV",
    "NY",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VA",
    "VT",
    "WA",
    "WI",
    "WV",
    "WY"
  ];

  constructor(private businessService: BusinessService, private router: Router) {}

  ngOnInit() {
  }

  createBusiness() {
    this.businessService.create(this.business).subscribe(() => {
      this.router.navigate(['portal']);
    });
  }
}