import { Component, OnInit } from "@angular/core";
import { MeService } from '../me.service';
import { isEmpty } from 'lodash';
import { Router } from '@angular/router';

@Component({
  selector: 'dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: [
    'dashboard.component.scss'
  ]
})
export class DashboardComponent implements OnInit {
  constructor(private meService: MeService, private router: Router) {}

  ngOnInit() {
    this.meService.getMe().subscribe(me => {
      if (isEmpty(me.businessIds)) {
        this.router.navigate([ 'portal', 'businessCreate' ]);
      }
    });
  }
}