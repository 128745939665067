<div class="loading" *ngIf="!business">
    <app-loader></app-loader>
</div>
<div class="main" *ngIf="business && business.openStatus">
    <div class="logoContainer">
        <img [src]="business.logoUrl">
    </div>
    <div class="business-name-heading">
        Check-in to
    </div>
    <div class="business-name">
        {{business.name}}
    </div>
    <div class="business-details">
        {{business.addressLine1}}
    </div>
    <div class="business-details" *ngIf="business.addressLine2">
        {{business.addressLine2}}
    </div>
    <div class="business-details">
        {{business.city}}, {{business.state}} {{business.zip}}
    </div>
    <div class="business-details">
        Phone: {{business.phoneNumber | slice:0:3}}-{{business.phoneNumber | slice:3:6}}-{{business.phoneNumber | slice:6:10}}
    </div>
    <div class="spacer">
         
    </div>
    <div class="button-container">
        <button class="next-button" (click)="onNext()">Next</button>
    </div>
</div>
<div class="main-closed" *ngIf="business && !business.openStatus">
    <div class="logoContainer">
        <img [src]="business.logoUrl">
    </div>
    <div class="business-name">
        {{business.name}}
    </div>
    <div class="business-name-closed">
        is currently closed
    </div>
    <div class="business-name-footer">
        Please check-in after we open.
    </div>
    <div class="business-details">
        {{business.addressLine1}}
    </div>
    <div class="business-details" *ngIf="business.addressLine2">
        {{business.addressLine2}}
    </div>
    <div class="business-details">
        {{business.city}}, {{business.state}} {{business.zip}}
    </div>
    <div class="business-details">
        Phone: {{business.phoneNumber | slice:0:3}}-{{business.phoneNumber | slice:3:6}}-{{business.phoneNumber | slice:6:10}}
    </div>
</div>