<div class="main">
    <app-step-dots currentStep="0"></app-step-dots>
    <div class="title">
        Tell us about yourself
    </div>
    <div class="field-name">
        First Name
    </div>
    <div class="input-container">
        <input type="text" class="input" [(ngModel)]="customer.firstName" (keyup)="checkFirstNameError()" autocomplete="given-name"/>
    </div>
    <div class="input-error">
        {{firstNameError}}
    </div>
    <div class="field-name">
        Last Name <span class="optional">(optional)</span>
    </div>
    <div class="input-container">
        <input type="text" class="input" [(ngModel)]="customer.lastName" autocomplete="family-name"/>
    </div>
    <div class="input-error">
        
    </div>
    <div class="field-name">
        Number of Guests
    </div>
    <div class="input-container">
        <select class="input" [(ngModel)]="checkin.partySize">
            <option *ngFor="let o of [1,2,3,4,5,6,7,8,9]">
                {{o}}
            </option>
        </select>
    </div>
    <div class="spacer">
            
    </div>
    <div class="hidden">
        <input type="text" [(ngModel)]="customer.phone" autocomplete="phone"/>
        <input type="text" [(ngModel)]="customer.email" autocomplete="email"/>
    </div>
    <div class="button-container">
        <button class="next-button" (click)="onNext()">Next</button>
    </div>
</div>
