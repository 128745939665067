import { Routes, RouterModule } from "@angular/router";
import { SignUpComponent } from 'src/auth/sign-up/sign-up.component';
import { SignUpConfirmationComponent } from './sign-up-confirmation/sign-up-confirmation.component';
import { NgModule } from '@angular/core';
import { AuthComponent } from './auth/auth.component';
import { SignUpSuccessComponent } from './sign-up-success/sign-up-success.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordConfirmationComponent } from './reset-password-confirmation/reset-password-confirmation.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {path: '', redirectTo: 'login', pathMatch: 'full'},
      {path: 'login', component: LoginComponent},
      {path: 'logout', component: LogoutComponent},
      {path: 'reset-password', component: ResetPasswordComponent},
      {path: 'reset-password-confirmation', component: ResetPasswordConfirmationComponent},
      {path: 'sign-up', component: SignUpComponent},
      {path: 'sign-up-confirmation', component: SignUpConfirmationComponent},
      {path: 'sign-up-success', component: SignUpSuccessComponent}
    ]
  },

]

@NgModule({
  imports: [ RouterModule.forChild(routes)],
  exports: [ RouterModule ]
})
export class AuthRoutingModule {}
